import { UPLOAD } from "@/core/services/store/request.module";
import { toSafeInteger } from "lodash";
import ObjectPath from "object-path";

export default {
  data() {
    return {
      preAttachment: [],
    };
  },
  methods: {
    removeCommonAttachment(index) {
      ObjectPath.del(this.preAttachment, index);
    },
    uploadCommonAttachments(attachments) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();

        const validAttachments = attachments.filter(
          ({ accepted_file }) => accepted_file instanceof File
        );

        if (validAttachments.length === 0) {
          return resolve([]);
        }

        validAttachments.forEach(
          (
            {
              accepted_file,
              file_name,
              show_in_mobile_app,
              file_type,
              file_size,
            },
            index
          ) => {
            formData.append(
              `files[${index}]`,
              accepted_file,
              `${file_name}.${file_type}`
            );
            formData.append(
              `files[${index}][show_in_mobile_app]`,
              toSafeInteger(show_in_mobile_app)
            );
            formData.append(`files[${index}][size]`, file_size);
          }
        );

        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then(({ data }) => resolve(data))
          .catch(reject);
      });
    },
  },
};

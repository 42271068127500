<template>
  <v-layout v-bind="$attrs">
    <v-flex :md="fullScreen ? 6 : 12">
      <label class="font-size-16 font-weight-600">{{ title }}</label>
      <table width="100%">
        <tbody>
          <template v-if="preAttachments.length > 0">
            <tr
              v-for="(row, index) in preAttachments"
              :key="`prev-attach-${index}`"
            >
              <td width="40%" class="pr-2 py-2">
                <v-text-field
                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                  placeholder="File Name"
                  label="File Name"
                  dense
                  solo
                  flat
                  readonly
                  color="cyan"
                  v-model="row.name"
                  hide-details
                ></v-text-field>
              </td>
              <td width="40%" class="pl-2 py-2">
                <v-text-field
                  placeholder="File Name"
                  label="File Name"
                  dense
                  solo
                  flat
                  color="cyan"
                  v-model="row.name"
                  hide-details
                  :suffix="`.${row?.extension}`"
                  @change="updateValue()"
                ></v-text-field>
              </td>
              <td width="15%" class="pl-2 py-2">
                <v-checkbox
                  color="cyan"
                  hide-details
                  dense
                  v-model="row.show_in_mobile_app"
                  @change="updateValue()"
                  label="show in tablet"
                />
              </td>
              <td width="5%" class="pl-2 py-2">
                <v-btn
                  color="red white--text lighten-4"
                  small
                  icon
                  :disabled="formLoading"
                  :loading="formLoading"
                  v-on:click="$emit('remove', index)"
                  class="custom-bold-button"
                  ><v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <tr v-for="(row, index) in attachments" :key="index">
            <td width="40%" class="pr-2 py-2">
              <v-file-input
                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                placeholder="Click here to select file"
                label="Click here to select file"
                prepend-icon=""
                color="cyan"
                solo
                flat
                v-model="row.accepted_file"
                dense
                v-on:change="update_file_name(index)"
                @change="updateValue()"
                hide-details
              ></v-file-input>
            </td>
            <td width="40%" class="pl-2 py-2">
              <v-text-field
                placeholder="File Name"
                label="File Name"
                dense
                solo
                flat
                color="cyan"
                v-model="row.file_name"
                hide-details
                :suffix="get_file_extension(row.file_type)"
                @change="updateValue()"
              ></v-text-field>
            </td>
            <td width="15%" class="pl-2 py-2">
              <v-checkbox
                color="cyan"
                hide-details
                dense
                v-model="row.show_in_mobile_app"
                @change="updateValue()"
                label="show in tablet"
              />
            </td>
            <td width="5%" class="pl-2 py-2">
              <v-btn
                color="red white--text lighten-4"
                small
                icon
                :disabled="formLoading"
                :loading="formLoading"
                v-on:click="remove_row('attachments', index)"
                class="custom-bold-button"
                ><v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="font-weight-600 red--text">
              Note: You can upload a maximum of {{ maxLimit }} files
            </td>
          </tr>
          <tr v-if="this.attachments.length < this.maxLimit">
            <td colspan="4">
              <v-btn
                color="cyan white--text"
                small
                :disabled="formLoading"
                :loading="formLoading"
                v-on:click="add_attachment_row()"
                class="custom-bold-button"
                >Add More...</v-btn
              >
            </td>
          </tr>
          <tr>
            <td height="10px" colspan="4"></td>
          </tr>
        </tbody>
      </table>
    </v-flex>
    <v-flex v-if="!fullScreen" md6> </v-flex>
  </v-layout>
</template>

<script>
import ObjectPath from "object-path";

export default {
  name: "create-attachment",
  inheritAttrs: false,
  props: {
    fullScreen: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
    preAttachments: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Attachments",
    },
    maxLimit: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      formLoading: false,
      attachments: [
        {
          file: null,
          file_edit: false,
          accepted_file: null,
          file_name: null,
          file_type: null,
          file_size: null,
          show_in_mobile_app: false,
        },
      ],
    };
  },
  methods: {
    updateValue() {
      const attachments = this.attachments
        .filter(function (row) {
          return (
            row.file_edit ||
            (row.accepted_file && row.accepted_file instanceof File)
          );
        })
        .slice(0, this.maxLimit);

      this.$emit("input", attachments);
    },
    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        this.updateValue();
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.attachments[index].file_size = file.size;
        this.add_attachment_row();
      }
    },
    add_attachment_row() {
      if (this.attachments.length < this.maxLimit) {
        this.attachments.push({
          file: null,
          file_edit: false,
          accepted_file: null,
          file_name: null,
          file_type: null,
          file_size: null,
          show_in_mobile_app: false,
        });
      }
      this.$nextTick(() => {
        this.updateValue();
      });
    },
  },
};
</script>
